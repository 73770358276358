import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { axiosTokenApi } from "../../../utils/axios";
import { Button, Input, Radio, Select, Spin, notification, Upload, message } from "antd";
import { ArrowLeftOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import ImageUpload from "../../ImageUpload";

const AddVideo=()=>{
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification(); 
  const openNotification = () => {
    api.info({
      message: `通知`,
      description:
        '正確に追加されました。',
      placement: 'top',
    });
  };

  const RadioGroup=Radio.Group;
  
  // -------view
  const [categorys, setCategorys] = useState([]);
  const [alltag, setAllTag]=useState([]);
  const [flag, setFlage] = useState(true);

  const [temple, setTemple]=useState("")
  const [order, setOrder]=useState()
  const [category, setCategory]=useState("")
  const [japanesetitle, setJapaneseTitle]=useState("")
  const [englishtitle, setEnglishTitle]=useState("")
  const [japanesedescription, setJapaneseDescription]=useState("")
  const [englishdescription, setEnglishDescription]=useState("")
  const [tags, setTags]=useState()
  const [time, setTime]=useState(0)
  const [video, setVideo]=useState()
  const [envideo, setEnVideo]=useState()
  const [file, setFile] = useState();

  const [validTemple, setValidTemple]=useState("")
  const [validOrder, setValidOrder]=useState("")
  const [validCategory, setValidCategory]=useState("")
  const [validJapanesetitle, setValidJapaneseTitle]=useState("")
  const [validEnglishtitle, setValidEnglishTitle]=useState("")
  const [validTags, setValidTags]=useState("")
  const [validTime, setValidTime]=useState("")
  const [validVideo, setValidVideo]=useState("")
  const [validEnVideo, setValidEnVideo]=useState("")
  const [validJapaneseDescription, setValidJapaneseDescription]=useState("")
  const [validEnglishDescription, setValidEnglishDescription]=useState("")
  const [validFile, setValidFile] = useState("");

  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    axiosTokenApi.get(process.env.REACT_APP_API+'/items/getallcategory').then((res) => {
      //  alert(res.data.message);
      setCategorys(res.data);
    });
    axiosTokenApi.get(process.env.REACT_APP_API+'/items/getalltags').then((res) => {
      //  alert(res.data.message);
      setAllTag(res.data);
    });
    // setDeleted(false);
    setFlage(false);
  }, [flag]);
  
  const changeTags=(value)=>{
    setTags(value);
  }

  const getVideo=(e)=>{
    setVideo(e.target.files[0])
  }
  const getEnVideo=(e)=>{
    setEnVideo(e.target.files[0])
  }
  const categoryList=()=>{
    var str=[];
    for(let i=0;i<categorys.length; i++){
      str.push({
        value:categorys[i].name,
        label:categorys.name
      });
    }
    return str;
  }
  const tagList=()=>{
    var str=[];
    for(let i=0;i<alltag.length; i++){
      str.push({
        value:alltag[i].name,
        label:`${alltag[i].name}(${alltag[i].en_name})`
      });
    }
    return str;
  }

  const handleAdd = async () =>{
    let added=false;
    let isValidate = true;

    if(!temple){
      setValidTemple("お寺の名前を入力してください。");
      isValidate = false;
    }else{
      setValidTemple("");
    }
    if(!order){
      setValidOrder("ホーム画面の並び順を入力してください。");
      isValidate = false;
    }else{
      setValidOrder("");
    }
    if(!category){
      setValidCategory("カテゴリを選択してください。");
      isValidate = false;
    }else{
      setValidCategory("");
    }
    if(!japanesetitle){
      setValidJapaneseTitle("動画タイトル(日)を入力してください。");
      isValidate = false;
    }else{
      setValidJapaneseTitle("");
    }
    // if(!englishtitle){
    //   setValidEnglishTitle("動画タイトル(英)を入力してください。");
    // }else{
    //   setValidEnglishTitle("");
    // }
    if(!japanesedescription){
      setValidJapaneseDescription("説明(日)を入力してください。");
      isValidate = false;
    }else{
      setValidJapaneseDescription("");
    }
    // if(!englishdescription){
    //   setValidEnglishDescription("説明(英)を入力してください。");
    // }else{
    //   setValidEnglishDescription("");
    // }
    if(!tags){
      setValidTags("タグを選択してください。");
      isValidate = false;
    }else{
      setValidTags("");
    }
    if(!time){
      setValidTime("再生時間を入力してください。");
      isValidate = false;
    }else{
      setValidTime("");
    }
    if(!video){
      setValidVideo("動画ファイルをアップロードしてください。");
      isValidate = false;
    }else{
      if(video.type==="video/mp4" || video.type==="video/avi"){
        setValidVideo("")
      }else{
        setValidVideo("アップロードできる音源ファイルはmp4/aviのみです！");
        isValidate = false;
      }
    }
    if(!envideo){
      // setValidEnVideo("動画ファイルをアップロードしてください。")
    }else{
      if(envideo.type==="video/mp4" || envideo.type==="video/avi"){
        setValidEnVideo("")
      }else{
        setValidEnVideo("アップロードできる音源ファイルはmp4/aviのみです！")
        isValidate = false;
      }
    }
    if(!file){
      setValidFile("サムネイルをアップロードしてください。");
      isValidate = false;
    }else{
      if(file.type==="image/png" || file.type==="image/jpg" || file.type==="image/jpeg"){
        setValidFile("");
      }else{
        setValidFile("アップロードできる画像ファイルはpng/jpg/jpegのみです！");
        isValidate = false;
      }
    }

    if (!isValidate) {
      return;
    }
    
    setSpinning(true);
    const data={
      temple:temple,
      order:order,
      time:time,
      tags:tags,
      category:category,
      japanesetitle:japanesetitle,
      japanesedescription:japanesedescription,
      video:video.name,
      filename: file.name
    }

    if (englishtitle) {
      data.englishtitle = englishtitle;
    }

    if (englishdescription) {
      data.englishdescription = englishdescription;
    }

    if (envideo) { 
      data.envideo = envideo.name;
    }

    const response = await axiosTokenApi.post(process.env.REACT_APP_API+"/items/additem_video", data);
    let itemId;
    if(response.status===200){
      added=true;
      itemId=response.data.itemId;
    }

    if(added){
      try {
        const formData1=new FormData();
        formData1.append('video',video);
        formData1.append('itemId', itemId);
        const config1={
          headers:{
            'content-type':'multipart/form-data',
          },
        };  
        await axiosTokenApi.post(process.env.REACT_APP_API+"/item/addvideo", formData1, config1)
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.error("Error uploading files: ", error);
          });  
        
        if (envideo) {
          const formData2=new FormData();
          formData2.append('video',envideo);
          formData2.append('itemId', itemId);
          const config2={
            headers:{
              'content-type':'multipart/form-data',
            },
          };  
          await axiosTokenApi.post(process.env.REACT_APP_API+"/item/addenvideo", formData2, config2)
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.error("Error uploading files: ", error);
            });
        }

      } catch (error) {
      }
    }

    if(file && added){
      const formData=new FormData();
      formData.append('file',file);
      formData.append('itemId', itemId);
      
      const config={
        headers:{
          'content-type':'multipart/form-data',
        },
      };  
      await axiosTokenApi.post(process.env.REACT_APP_API+"/item/addimage", formData, config);
    }

    openNotification();
    setSpinning(false);
    setTimeout(() => {
      navigate("/manage/video");
    }, 1000);
  }

  // ----------add
  return(
    <>
      {contextHolder}
      <Spin spinning={spinning} fullscreen />
      <div className="p-10 bg-white shadow-md mx-auto my-3 overflow-y-auto main"> 
        <div className="w-full h-10 mb-2">
          <p className="text-2xl text-left mx-10 font-bold">動画新規登録</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">お寺</div>
            <Input type="text" value={temple} onChange={(e)=>setTemple(e.target.value)} className="w-3/4"/>
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validTemple}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">ホーム画面の並び順</div>
              <Input type="number" min={1} max={30} onChange={(e)=>setOrder(e.target.value)} className="w-1/4"/>
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validOrder}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">カテゴリ</div>
            <div className="w-3/4 text-left py-2">
              <RadioGroup value={category} onChange={(e)=>setCategory(e.target.value)}>
                {
                  categoryList().map((data, index)=>{
                    return(
                      <Radio key={index} value={data.value}>{data.value}</Radio>
                        )
                  })
                }
                </RadioGroup>
            </div>
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validCategory}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">動画タイトル（日）</div>
            <Input type="text" value={japanesetitle} onChange={(e)=>setJapaneseTitle(e.target.value)} className="w-3/4"/>
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validJapanesetitle}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">動画タイトル（英）</div>
            <Input type="text" value={englishtitle} onChange={(e)=>setEnglishTitle(e.target.value)} className="w-3/4"/>
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validEnglishtitle}</p>
        </div>
        <div className="w-full mb-2 flex">
          <div className="w-full h-20 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">説明（日）</div>
            <TextArea value={japanesedescription} onChange={(e)=>setJapaneseDescription(e.target.value)} className="w-3/4 h-20"/>
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validJapaneseDescription}</p>
        </div>
        <div className="w-full mb-2 flex">
          <div className="w-full h-20 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">説明（英）</div>
            <TextArea value={englishdescription} onChange={(e)=>setEnglishDescription(e.target.value)} className="w-3/4 h-20"/>
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validEnglishDescription}</p>
        </div>
        <div className="w-full mb-2 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">タグ</div>
            <Select className="w-3/4" 
              mode="multiple"
              value={tags}
              onChange={(value) => {
                changeTags(value)
            }}
              options={
                tagList()
              }
              />
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validTags}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">再生時間</div>
            <Input type="number" value={time} onChange={(e)=>setTime(e.target.value)} className="w-40"/>
            <p className="text-sm m-2">分</p>
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validTime}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">動画ファイルアップロード（日）</div>
            <div className="w-1/2 text-left text-sm font-medium py-2">
              <Input type="file" onChange={getVideo}/>
            </div>
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validVideo}</p>
        </div>
        <div className="w-full mb-1 flex">
          <div className="w-full h-10 px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">動画ファイルアップロード（英）</div>
            <div className="w-1/2 text-left text-sm font-medium py-2">
              <Input type="file" onChange={getEnVideo}/>
            </div>
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validEnVideo}</p>
        </div>
        <div className="w-full mb-2 flex">
          <div className="w-full px-10 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2">サムネイル</div>
            <div className="w-1/2 text-left text-sm font-medium py-2">
              <ImageUpload file={file} setFile={setFile}/> 
            </div>
          </div>
        </div>
        <div className="w-full mb-1 flex">
            <div className="w-1/4 text-center text-sm font-medium py-2"></div>
            <p className="mx-6 text-red-500">{validFile}</p>
        </div>
        <div className="w-full text-center my-10 flex">
          <div className="w-1/4 text-center">
          <Link to="/manage/video"><Button className="w-48 m-auto"><ArrowLeftOutlined /> 戻 る</Button></Link>
          </div>
          <div className="w-3/4 text-center">
          <Button type="primary" onClick={handleAdd} className="w-80 text-black border-gray-300"><div className="flex mx-28"><SaveOutlined className="mr-2"/>登 録</div></Button>

          </div>        
        </div>
      </div>
    </>
  );
  
};
export default AddVideo;