import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { Button, message } from "antd";
import { EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';



const ImageUpload = ({file, setFile}) => {
  const [images, setImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const onChange = (imageList) => {
    // Check for image dimensions
    setImages(imageList);
    const img = new Image();
    img.src = imageList[0]?.data_url;
    img.onload = () => {
      if (img.width > 500 || img.height > 500) {
        message.error(`画像のサイズは500x500ピクセルを超えないこと！`);
        setImages([]);
      } else {
        setImages(imageList);
        setFile(imageList[0].file)
      }
    };
  };

  return (
    <div className="App">
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={1}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          onImageRemoveAll,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          // Write your building UI
          <div className="flex flex-col items-start">
            <button
              style={isDragging ? { color: 'red' } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </button>
            <p className='pt-4 text-black'>※サイズ制限は500×500です。</p>
            {imageList.map((image, index) => (
              <div key={index} className="flex justify-between items-center w-full border rounded-md mt-3 p-2">
                <div className='flex items-center'>
                  <img src={image['data_url']} alt="" className='w-12' />
                  <p className='ml-5'>{image.file.name}</p>
                </div>
                <div className='flex'>
                    <button className='mx-2' onClick={() => onImageUpdate(index)}><EditOutlined/></button>
                    <button className='mx-2' onClick={ ()=> onImageRemoveAll()}><DeleteOutlined/></button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default ImageUpload;